import React from "react";
import PropTypes from "prop-types";
import { IonButton, IonList } from "@ionic/react";

function FirstService({ isSecondService = false, onAction, currentStatus }) {
  const handleService = async (serviceType) => {
    const { current_player } = currentStatus;
    const { athleteId } = current_player;
    onAction(serviceType, { athleteId });
  };
  return (
    <>
      <IonList className="ion-padding" flex-direction="column">
        <IonButton size="large" onClick={() => handleService("valid_service")}>
          Valido
        </IonButton>
        <IonButton
          size="large"
          onClick={() =>
            handleService(isSecondService ? "double_fault" : "fault")
          }
        >
          {isSecondService ? "Doppio Fallo" : "Fallo"}
        </IonButton>
        <IonButton size="large" onClick={() => handleService("ace")}>
          ACE
        </IonButton>
      </IonList>
    </>
  );
}

FirstService.propTypes = {
  isSecondService: PropTypes.bool,
  onAction: PropTypes.func,
  currentStatus: PropTypes.object,
};

export default FirstService;
