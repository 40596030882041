import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import useSubscriptionsByUserAndCoach from "../../../../hooks/subscriptions/useSubscriptionsByUserAndCoach";
import SubscriptionCard from "./SubscriptionCard";
import {
  IonDatetime,
  IonDatetimeButton,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonRefresher,
  IonRefresherContent,
} from "@ionic/react";
import SubscriptionEditModal from "../../../../components/SubscriptionEditModal/SubscriptionEditModal";
import CardListHeader from "../../../../components/CardListHeader/CardListHeader";
import useAPI from "../../../../hooks/basic/useAPI";

const fromDateDefault = new Date();
fromDateDefault.setDate(1);
// create a variable toDate that contains the first day of the next month
const toDateDefault = new Date(fromDateDefault);
toDateDefault.setMonth(toDateDefault.getMonth() + 1);

function SubscriptionsContent({
  coachId,
  userId,
  showHeader = true,
  canAddSubscription = true,
  defaultFilterValue = "Non Pagati",
}) {
  const [fromDate, setFromDate] = useState(fromDateDefault);
  const [toDate, setToDate] = useState(toDateDefault);
  const [showFromDateModal, setShowFromDateModal] = useState(false);
  const [showToDateModal, setShowToDateModal] = useState(false);
  const [filterValue, setFilterValue] = useState("");
  const [data, setData] = useState([]);
  const { callAPI } = useAPI();

  const loadData = async () => {
    let normalizedStartDate = new Date(fromDate);
    normalizedStartDate.setHours(0);
    normalizedStartDate.setMinutes(0);
    normalizedStartDate.setSeconds(0);
    normalizedStartDate.setMilliseconds(0);

    let normalizedEndDate = new Date(toDate);
    normalizedEndDate.setHours(0);
    normalizedEndDate.setMinutes(0);
    normalizedEndDate.setSeconds(0);
    normalizedEndDate.setMilliseconds(0);

    normalizedEndDate = new Date(
      normalizedEndDate.getTime() -
      normalizedEndDate.getTimezoneOffset() * 60000,
    );
    normalizedStartDate = new Date(
      normalizedStartDate.getTime() -
      normalizedStartDate.getTimezoneOffset() * 60000,
    );

    const data = await callAPI({
      url: "/payments-by-coach",
      params: {
        coachId,
        fromDate: normalizedStartDate,
        toDate: normalizedEndDate,
        userId,
      },
      preventCall: !coachId,
    });

    setData(data);
  };

  useEffect(() => {
    if (coachId) {
      loadData();
    }
  }, [coachId, fromDate, toDate]);
  const handleRefresh = (e) => {
    loadData();
    e.detail.complete();
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const [selectedFilterValue, setSelectedFilterValue] =
    useState(defaultFilterValue);

  const filteredData = data
    ?.filter(
      (a) =>
        filterValue === "" ||
        a.user_full_name.toLowerCase().indexOf(filterValue.toLowerCase()) !==
        -1,
    )
    .filter((subscription) => {
      if (selectedFilterValue === "Non Pagati") {
        return subscription.paid === false;
      }
      return true;
    });

  return (
    <div>
      <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
        <IonRefresherContent></IonRefresherContent>
      </IonRefresher>
      {showHeader && (
        <CardListHeader
          filterOptions={{
            defaultSelectedOption: selectedFilterValue,
            options: ["Tutti", "Non Pagati"],
          }}
          canAddSubscription={canAddSubscription}
          onAddClick={() => {
            if (!coachId) return;
            setSelectedSubscription(null);
            setIsModalOpen(true);
          }}
          onFilterChange={(option) => {
            setSelectedFilterValue(option);
          }}
        />
      )}
      <IonList>
        <IonItem>
          <IonLabel>Data Inizio</IonLabel>
          <IonDatetimeButton
            datetime="calendarFromDate"
            onClick={() => setShowFromDateModal(true)}
          ></IonDatetimeButton>
          <IonModal
            keepContentsMounted={true}
            onIonModalDidDismiss={() => setShowFromDateModal(false)}
            isOpen={showFromDateModal}
          >
            <IonDatetime
              presentation="date"
              id="calendarFromDate"
              value={fromDate.toISOString()}
              onIonChange={(e) => {
                setShowFromDateModal(false);
                setFromDate(new Date(e.detail.value));
              }}
            ></IonDatetime>
          </IonModal>
        </IonItem>
        <IonItem>
          <IonLabel>Data Fine</IonLabel>
          <IonDatetimeButton
            datetime="calendarToDate"
            onClick={() => setShowToDateModal(true)}
          ></IonDatetimeButton>
          <IonModal
            keepContentsMounted={true}
            onIonModalDidDismiss={() => setShowToDateModal(false)}
            isOpen={showToDateModal}
          >
            <IonDatetime
              id="calendarToDate"
              presentation="date"
              value={toDate.toISOString()}
              onIonChange={(e) => {
                setShowToDateModal(false);
                setToDate(new Date(e.detail.value));
              }}
            ></IonDatetime>
          </IonModal>
        </IonItem>
        <IonItem>
          <IonLabel>Atleta</IonLabel>
          <IonInput
            style={{ textAlign: "right" }}
            value={filterValue}
            onIonInput={(e) => {
              setFilterValue(e.detail.value);
            }}
          ></IonInput>
        </IonItem>
      </IonList>
      {filteredData &&
        filteredData.map((subscription) => (
          <div
            key={subscription.id}
            onClick={() => {
              setSelectedSubscription(subscription);
              setIsModalOpen(true);
            }}
          >
            <SubscriptionCard key={subscription.id} data={subscription} />
          </div>
        ))}
      {isModalOpen && coachId && (
        <SubscriptionEditModal
          isOpen={isModalOpen}
          subscription={selectedSubscription}
          userId={userId}
          onDismiss={() => setIsModalOpen(false)}
          onConfirm={() => {
            setIsModalOpen(false);
            loadData();
          }}
        />
      )}
    </div>
  );
}

SubscriptionsContent.propTypes = {
  canAddSubscription: PropTypes.bool,
  coachId: PropTypes.string.isRequired,
  showHeader: PropTypes.bool,
  userId: PropTypes.string.isRequired,
};

export default SubscriptionsContent;
