import { IonContent, IonPage } from "@ionic/react";
import React, { useContext } from "react";
import { AuthenticationContext } from "../../../providers/AuthenticationProvider/AuthenticationProvider";
import LessonsContent from "../UserManagement/sub/LessonsContent";

const LessonLogs = () => {
  const { appUserId, isCoach } = useContext(AuthenticationContext);

  return (
    <IonPage>
      <IonContent>
        <LessonsContent
          coachId={isCoach && appUserId}
          userId={!isCoach && appUserId}
          canAddSubscription={false}
          showHeader={false}
        />
      </IonContent>
    </IonPage>
  );
};

export default LessonLogs;
