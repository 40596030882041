import React, { useState } from "react";
import PropTypes from "prop-types";
import { IonButton, IonItem, IonList } from "@ionic/react";

function InGame({ onAction, match, currentStatus }) {
  const [shotType, setShotType] = useState(null);
  const [team, setTeam] = useState(null);

  const firstSelection = (shotType, team) => {
    setShotType(shotType);
    setTeam(team);
  };

  const secondSelection = (mode) => {
    const currentTeam = match[team][0];

    const { athleteId } = currentTeam;
    onAction(shotType, { athleteId, mode });
    setShotType(null);
    setTeam(null);
  };

  return (
    <>
      {!shotType && (
        <IonList className="ion-padding" flex-direction="column">
          <IonItem>
            Prima Squadra
            <IonButton
              size="large"
              onClick={() => firstSelection("winner", "team_a")}
            >
              Vincente
            </IonButton>
            <IonButton
              size="large"
              onClick={() => firstSelection("error", "team_a")}
            >
              Gratuito
            </IonButton>
            <IonButton
              size="large"
              onClick={() => firstSelection("forced", "team_a")}
            >
              Forzato
            </IonButton>
          </IonItem>
          <IonItem>
            Seconda Squadra
            <IonButton
              size="large"
              onClick={() => firstSelection("winner", "team_b")}
            >
              Vincente
            </IonButton>
            <IonButton
              size="large"
              onClick={() => firstSelection("error", "team_a")}
            >
              Gratuito
            </IonButton>
            <IonButton
              size="large"
              onClick={() => firstSelection("forced", "team_a")}
            >
              Forzato
            </IonButton>
          </IonItem>
        </IonList>
      )}
      {shotType && (
        <IonList className="ion-padding" flex-direction="column">
          <IonItem>
            <IonButton size="large" onClick={() => secondSelection("forehand")}>
              Dritto
            </IonButton>
            <IonButton size="large" onClick={() => secondSelection("backhand")}>
              Rovescio
            </IonButton>
            <IonButton size="large" onClick={() => secondSelection("volley")}>
              Volee
            </IonButton>
            <IonButton size="large" onClick={() => secondSelection("smash")}>
              Smash
            </IonButton>
          </IonItem>
        </IonList>
      )}
    </>
  );
}

InGame.propTypes = {
  onAction: PropTypes.func,
  currentStatus: PropTypes.object,
};

export default InGame;
