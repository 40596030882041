import React from "react";
import PropTypes from "prop-types";
import { IonCard, IonCardContent, IonItem, IonList } from "@ionic/react";
import CurrentPlayerIndicator from "../CurrentPlayerIndicator/CurrentPlayerIndicator";

function MatchCard({ match }) {
  // check who is serving
  const servingPlayer = match?.currentStatus?.current_player;
  const servingPlayerId = servingPlayer?.athleteId;

  console.log("match?.currentStatus", match?.currentStatus);
  return (
    <IonCard className="MatchCard">
      <IonCardContent>
        <IonList>
          <IonItem>
            {match?.team_a?.map((player) => (
              <>
                <span key={player.athleteId}>{player.name}</span>
                {player.athleteId === servingPlayerId && (
                  <CurrentPlayerIndicator />
                )}
              </>
            ))}
            <div style={{ marginLeft: "auto" }}>
              P{match?.currentStatus?.team_a.points}&nbsp;
              G{match?.currentStatus?.team_a.games}&nbsp;
              S{match?.currentStatus?.team_a.sets}&nbsp;
            </div>
          </IonItem>
          <IonItem>
            {match?.team_b?.map((player) => (
              <>
                <span key={player.athleteId}>
                  {player.name}{" "}
                </span>
                {player.athleteId === servingPlayerId && (
                  <CurrentPlayerIndicator />
                )}
                <div style={{ marginLeft: "auto" }}>
                  P{match?.currentStatus?.team_b.points}&nbsp;
                  G{match?.currentStatus?.team_b.games}&nbsp;
                  S{match?.currentStatus?.team_b.sets}&nbsp;
                </div>
              </>
            ))}
          </IonItem>
        </IonList>
      </IonCardContent>
    </IonCard>
  );
}

MatchCard.propTypes = {};

export default MatchCard;
