import React, { useState } from "react";
import PropTypes from "prop-types";

import {
  IonBadge,
  IonButton,
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonIcon,
  IonImg,
  IonText,
} from "@ionic/react";
import { calendarOutline, cardOutline, personOutline } from "ionicons/icons";

import "./styles/SubscriptionCard.scss";
import { FormattedDate } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons";
import useGroupLogsByPaymentId from "../../../../hooks/groupLogs/useGroupLogsByPaymentId";
import useLessonsByPaymentId from "../../../../hooks/lessons/useLessonsByPaymentId";

function isSubscriptionActive(start_date, end_date) {
  const now = new Date();
  return (
    now >= new Date(start_date._seconds * 1000) &&
    now <= new Date(end_date._seconds * 1000)
  );
}

function SubscriptionCard({ data, imageUrl = "ticket.png", className }) {
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);
  const {
    data: subscriptionByPaymentData,
    error: subscriptionError,
    isLoading: isLoadingSubscriptionData,
  } = useGroupLogsByPaymentId(data.id, !isDetailsOpen);
  const {
    data: lessonByPaymentData,
    error: lessonError,
    isLoading: isLoadingLessonData,
  } = useLessonsByPaymentId(data.id, !isDetailsOpen);
  const {
    id,
    start_date,
    end_date,
    description,
    paid,
    payment_date,
    payment_channel,
    user_full_name,
  } = data;

  const groupLogsWithNestedLessons = subscriptionByPaymentData?.map(
    (groupLog) => {
      const groupLogLessons = lessonByPaymentData?.filter(
        (lesson) => lesson.group_log_id === groupLog.id,
      );
      return {
        ...groupLog,
        lessonList: groupLogLessons || [],
      };
    },
  );

  return (
    <IonCard
      key={id}
      style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
      className={
        className ? `SubscriptionCard ${className}` : "SubscriptionCard"
      }
    >
      {/* <IonImg
        src={imageUrl}
        style={{
          width: "55px",
          paddingLeft: "1rem",
          boxSizing: "content-box",
        }}
      /> */}
      <IonCardContent style={{ flex: 1, paddingLeft: "1rem" }}>
        <IonText>
          <h5>
            <IonIcon icon={calendarOutline}></IonIcon>
            <FormattedDate
              value={start_date._seconds * 1000}
              day="2-digit"
              month="2-digit"
              year="2-digit"
            />
            &nbsp;-&nbsp;
            <FormattedDate
              value={end_date._seconds * 1000}
              day="2-digit"
              month="2-digit"
              year="2-digit"
            />
          </h5>
          {isSubscriptionActive(start_date, end_date) && (
            <IonBadge color="success">Attivo</IonBadge>
          )}
          {!isSubscriptionActive(start_date, end_date) && (
            <IonBadge color="primary">Non attivo</IonBadge>
          )}
        </IonText>
        <IonCardTitle>{description}</IonCardTitle>
        <IonText>
          <h5>
            <IonIcon icon={personOutline}></IonIcon>
            {user_full_name}
          </h5>
        </IonText>
        <IonText>
          <h5>
            <IonIcon icon={cardOutline}></IonIcon>
            {paid ? (
              <span style={{ color: "green" }}>
                {payment_date ? (
                  <>
                    Pagato il&nbsp;
                    <FormattedDate
                      value={payment_date._seconds * 1000}
                      day="2-digit"
                      month="2-digit"
                      year="2-digit"
                    />
                  </>
                ) : null}
                {payment_channel && <>&nbsp;tramite {payment_channel}</>}
              </span>
            ) : (
              <span style={{ color: "red" }}>Non pagato</span>
            )}
          </h5>
        </IonText>
      </IonCardContent>
      <IonCardContent
        style={{
          flex: "0 1 auto",
          paddingLeft: "1rem",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <IonButton
          fill="clear"
          onClick={(e) => {
            e.stopPropagation();
            setIsDetailsOpen(!isDetailsOpen);
          }}
        >
          <FontAwesomeIcon
            icon={faChevronUp}
            style={{
              fontSize: "1.5em",
            }}
          />
        </IonButton>
      </IonCardContent>
      {isDetailsOpen && (
        <IonCardContent
          className="SubscriptionCard__details"
          style={{
            flexBasis: "100%",
          }}
        >
          <h2>Diario lezioni</h2>
          {groupLogsWithNestedLessons?.map((groupLog) => (
            <div key={groupLog.id} className="SubscriptionCard__lessons">
              <h5>
                {groupLog.description}
                &nbsp;-&nbsp;(
                <FormattedDate
                  value={groupLog.from_date.seconds * 1000}
                  day="2-digit"
                  month="2-digit"
                  year="2-digit"
                />
                &nbsp;-&nbsp;
                <FormattedDate
                  value={groupLog.to_date.seconds * 1000}
                  day="2-digit"
                  month="2-digit"
                  year="2-digit"
                />
                )
              </h5>
              {groupLog.lessons < groupLog.minimum_lessons ? (
                <IonBadge color="danger">Lezioni da recuperare</IonBadge>
              ) : (
                ""
              )}
              <h6>
                Lezioni svolte{" "}
                <span className="SubscriptionCard__lesson_number">
                  {groupLog.lessons || "-"}
                </span>{" "}
                su{" "}
                <span className="SubscriptionCard__lesson_number">
                  {groupLog.minimum_lessons}
                </span>{" "}
                pianificate
              </h6>
              <ul>
                {groupLog.lessonList.map((lesson) => (
                  <li key={lesson.id}>
                    <FormattedDate
                      value={lesson.to_date.seconds * 1000}
                      day="2-digit"
                      month="2-digit"
                      year="2-digit"
                      weekday="long"
                    />
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </IonCardContent>
      )}
    </IonCard>
  );
}

SubscriptionCard.propTypes = {
  href: PropTypes.string,
  imageUrl: PropTypes.string,
  className: PropTypes.string,
  data: PropTypes.shape({
    id: PropTypes.string,
    start_date: PropTypes.shape({ seconds: PropTypes.number }),
    end_date: PropTypes.shape({ seconds: PropTypes.number }),
    participants: PropTypes.array,
    fieldName: PropTypes.string,
    clubName: PropTypes.string,
    label: PropTypes.string,
    description: PropTypes.string,
    paid: PropTypes.bool,
    payment_date: PropTypes.shape({ seconds: PropTypes.number }),
    payment_channel: PropTypes.string,
  }),
};

export default SubscriptionCard;
