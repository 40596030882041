import React, { Fragment, useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  IonAlert,
  IonButton,
  IonChip,
  IonContent,
  IonFooter,
  IonImg,
  IonPage,
  IonText,
  useIonToast,
  useIonViewWillEnter,
  useIonViewDidLeave,
} from "@ionic/react";
import useBooking from "../../hooks/bookings/useBooking";
import useDeleteBooking from "../../hooks/bookings/useDeleteBooking";
import { AuthenticationContext } from "../../providers/AuthenticationProvider/AuthenticationProvider";
import Spinner from "../../components/Spinner/Spinner";
import { useHistory } from "react-router";
import PageTitle from "../../components/PageTitle/PageTitle";
import { FormattedDate, FormattedTime } from "react-intl";
import useAPI from "../../hooks/basic/useAPI";
import BuySubscriptionModal from "../../components/BuySubscriptionModal/BuySubscriptionModal";

const Detail = ({ match }) => {
  const history = useHistory();
  const [present] = useIonToast();
  const [shouldRecoverLesson, setShouldRecoverLesson] = useState(null);
  const parsedBookingId =
    match.params.bookingId !== "add" ? match.params.bookingId : null;
  const { appUserId } = useContext(AuthenticationContext);
  const { data, isLoading, error, refreshData } = useBooking(parsedBookingId);
  const deleteBooking = useDeleteBooking();
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [showSubscribeAlert, setShowSubscribeAlert] = useState(false);
  const [showUnsubscribeAlert, setShowUnsubscribeAlert] = useState(false);
  const [isInPage, setIsInPage] = useState(false);
  const [canSubscribeEvent, setCanSubscribeEvent] = useState(false);
  const [canUnsubscribeEvent, setCanUnsubscribeEvent] = useState(false);
  const [canCancelEvent, setCanCancelEvent] = useState(false);
  const [canBuyEvent, setCanBuyEvent] = useState(false);
  const [showBuySubscriptionModal, setShowBuySubscriptionModal] =
    useState(false);

  const { callAPI } = useAPI();
  useIonViewWillEnter(() => {
    refreshData();
    setIsInPage(true);
  });

  useIonViewDidLeave(() => {
    setShowDeleteAlert(false);
    setShowSubscribeAlert(false);
    setShowUnsubscribeAlert(false);
    setIsInPage(false);
  });

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  const {
    clubName,
    description,
    fieldName,
    from,
    label,
    participants,
    participantUserIds = [],
    to,
  } = data || {};

  const checkIfShouldRecoverLesson = async () => {
    if (!appUserId) {
      return null;
    }
    try {
      const response = await callAPI({
        url: `/should-recover-lesson`,
        params: {
          user_id: appUserId,
          lesson_id: parsedBookingId,
        },
      });
      setShouldRecoverLesson(response);
    } catch (error) {
      console.error("Error checking if should recover lesson", error);
      return null;
    }
  };

  useEffect(() => {
    checkIfShouldRecoverLesson();
  }, [appUserId]);

  useEffect(() => {
    if (appUserId && data && shouldRecoverLesson != null) {
      const hoursBeforeEvent = (from.seconds - Date.now() / 1000) / 3600;
      // if the event is in the past or remaining less than 24 hours to the event
      const isPastOrCloseEvent = hoursBeforeEvent < 0 || hoursBeforeEvent < 24;

      const canCancelEvent = appUserId === data.relatedSupervisorId;
      const canUnsubscribeEvent =
        participantUserIds.includes(appUserId) && !isPastOrCloseEvent;
      const canSubscribeEvent =
        !participantUserIds.includes(appUserId) &&
        shouldRecoverLesson &&
        !isPastOrCloseEvent;
      const canBuyEvent =
        !canCancelEvent &&
        !canSubscribeEvent &&
        !canUnsubscribeEvent &&
        !shouldRecoverLesson &&
        !isPastOrCloseEvent;
      setCanCancelEvent(canCancelEvent);
      setCanSubscribeEvent(canSubscribeEvent);
      setCanUnsubscribeEvent(canUnsubscribeEvent);
      setCanBuyEvent(canBuyEvent);
    }
  }, [data, appUserId, shouldRecoverLesson]);

  return (
    <IonPage>
      {isLoading && isInPage && <Spinner />}
      {error && <p>Error: {error.message}</p>}
      {!isLoading && !error && isInPage && data && (
        <>
          <PageTitle>Dettaglio evento</PageTitle>
          <IonContent className="ion-padding">
            <Fragment>
              <div>
                <IonImg
                  src="smash.png"
                  style={{
                    height: "85px",
                  }}
                />
                <IonText class="ion-text-center ion-padding-top ion-padding-vertical">
                  <h1>{label || description || "Prenotazione"}</h1>
                </IonText>
                <h4 className="ion-text-center">
                  <FormattedDate
                    value={from.seconds * 1000}
                    day="2-digit"
                    month="long"
                    weekday="short"
                    year="numeric"
                  />
                  ,&nbsp;
                  <FormattedTime value={from.seconds * 1000} />
                  &nbsp;-&nbsp;
                  <FormattedTime value={to.seconds * 1000} />
                </h4>
              </div>
              <div className="ion-text-center">
                <h5>
                  {clubName} - {fieldName}
                </h5>
              </div>
              <div className="ion-text-center">
                <h2>Partecipanti</h2>
                {participants.map(({ id, userId, fullName }) => (
                  <IonChip color="primary" key={userId || id}>
                    {fullName}
                  </IonChip>
                ))}
              </div>
            </Fragment>
          </IonContent>
          <IonFooter
            className="ion-no-border ion-padding"
            style={{
              display: "flex",
              flexDirection: "column",
              gap: ".5rem",
            }}
          >
            {canCancelEvent && (
              <div>
                <IonButton
                  color="danger"
                  expand="block"
                  size="large"
                  onClick={() => setShowDeleteAlert(true)}
                >
                  Cancella Evento
                </IonButton>
                <IonAlert
                  header="Vuoi cancellare l'evento?"
                  isOpen={showDeleteAlert}
                  onDidDismiss={() => setShowDeleteAlert(false)}
                  buttons={[
                    {
                      text: "Annulla",
                      role: "cancel",
                      handler: async () => {
                        setShowDeleteAlert(false);
                      },
                    },
                    {
                      text: "Conferma",
                      role: "confirm",
                      handler: async () => {
                        await deleteBooking(parsedBookingId);
                        setShowDeleteAlert(false);
                        // go back to the previous page
                        history.push("/");
                      },
                    },
                  ]}
                ></IonAlert>
              </div>
            )}
            {canUnsubscribeEvent && (
              <div>
                <IonButton
                  color="danger"
                  expand="block"
                  size="large"
                  onClick={() => setShowUnsubscribeAlert(true)}
                >
                  Cancellati
                </IonButton>
                <IonAlert
                  header="Vuoi cancellarti dall'evento?"
                  isOpen={showUnsubscribeAlert}
                  buttons={[
                    {
                      text: "Annulla",
                      role: "cancel",
                      handler: async () => {
                        setShowUnsubscribeAlert(false);
                      },
                    },
                    {
                      text: "Conferma",
                      role: "confirm",
                      handler: async () => {
                        try {
                          await callAPI({
                            method: "post",
                            url: "/unsubscribe-lesson",
                            data: {
                              userId: appUserId,
                              lessonId: parsedBookingId,
                            },
                          });

                          present({
                            message: "Ti sei cancellato dall'evento",
                            duration: 500,
                            color: "success",
                            position: "top",
                          });
                        } catch (error) {
                          console.error(
                            "Error deleting the participation",
                            error,
                          );
                          present({
                            message: "Errore nella cancellazione",
                            duration: 500,
                            color: "danger",
                            position: "top",
                          });
                        } finally {
                          setShowUnsubscribeAlert(false);
                          // go back to the previous page
                          history.push("/");
                        }
                      },
                    },
                  ]}
                  onDidDismiss={() => {
                    setShowUnsubscribeAlert(false);
                  }}
                ></IonAlert>
              </div>
            )}
            {canSubscribeEvent && (
              <div>
                <IonButton
                  color="success"
                  expand="block"
                  size="large"
                  onClick={() => setShowSubscribeAlert(true)}
                >
                  Iscriviti
                </IonButton>
                <IonAlert
                  header="Vuoi iscriverti all'evento?"
                  isOpen={showSubscribeAlert}
                  onDidDismiss={() => setShowSubscribeAlert(false)}
                  buttons={[
                    {
                      text: "Annulla",
                      role: "cancel",
                      handler: async () => {
                        setShowSubscribeAlert(false);
                      },
                    },
                    {
                      text: "Conferma",
                      role: "confirm",
                      handler: async () => {
                        try {
                          await callAPI({
                            method: "post",
                            url: "/subscribe-lesson",
                            data: {
                              userId: appUserId,
                              lessonId: parsedBookingId,
                            },
                          });
                          present({
                            message: "Iscrizione effettuata con successo!",
                            duration: 500,
                            color: "success",
                            position: "top",
                          });
                        } catch (error) {
                          console.error(
                            "Error subscribing to the event",
                            error,
                          );
                          present({
                            message: "Posto non disponibile",
                            duration: 500,
                            color: "danger",
                            position: "top",
                          });
                        } finally {
                          setShowSubscribeAlert(false);
                          // go back to the previous page
                          history.push("/");
                        }
                      },
                    },
                  ]}
                ></IonAlert>
              </div>
            )}
            {canBuyEvent && (
              <>
                <div>
                  <IonButton
                    color="success"
                    expand="block"
                    size="large"
                    onClick={() => setShowBuySubscriptionModal(true)}
                  >
                    Acquista
                  </IonButton>
                </div>
                {showBuySubscriptionModal && (
                  <BuySubscriptionModal
                    type="single"
                    objectId={parsedBookingId}
                    supervisorId={data.relatedSupervisorId}
                    onDismiss={() => setShowBuySubscriptionModal(false)}
                    onConfirm={async (selectedPackage) => {
                      await callAPI({
                        method: "post",
                        url: "/buy-single-lesson",
                        data: {
                          userId: appUserId,
                          lessonId: parsedBookingId,
                          paymentId: selectedPackage.id,
                        },
                      });
                      history.push("/");
                    }}
                  />
                )}
              </>
            )}
          </IonFooter>
        </>
      )}
    </IonPage>
  );
};

export default Detail;

Detail.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      bookingId: PropTypes.string,
    }),
  }),
};
