import { useContext, useEffect } from "react";
import useFirestore from "../basic/useFirestore";
import { collection, getDocs, or, orderBy, query, where } from "firebase/firestore";
import { FirebaseContext } from "../../providers/FirebaseProvider/FirebaseProvider";

function useGroupLogsByPaymentId(paymentId, preventCall) {
  const { firestoreDb } = useContext(FirebaseContext);
  const firestoreQueryMethod = async () =>
    getDocs(
      query(
        collection(firestoreDb, "group-logs"),
        where("payment_id", "==", paymentId),
        orderBy("from_date", "desc"),
      ),
    );

  const parsingFuntion = (data) => {
    const parsedData = data.docs.map((doc) => {
      return {
        id: doc.id,
        ...doc.data(),
      };
    });
    console.log('aaaaaaaaaaaaa', parsedData);
    return parsedData;
  };

  const { data, error, isLoading, refreshData } = useFirestore(
    firestoreQueryMethod,
    parsingFuntion,
    preventCall,
  );

  useEffect(() => {
    if (preventCall) return;
    refreshData();
  }, [paymentId, preventCall]);

  return {
    data,
    error,
    isLoading,
    refreshData,
  };
}

export default useGroupLogsByPaymentId;
