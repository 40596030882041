import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { IonButton, IonModal } from "@ionic/react";
import classnames from "classnames";
import "./styles/BuySubscriptionModal.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import SubscriptionTypeSlide from "./sub/SubscriptionTypeSlide";
import useAPI from "../../hooks/basic/useAPI";

function BuySubscriptionModal({
  type = "single",
  supervisorId,
  onDismiss,
  onConfirm,
}) {
  const [packages, setPackages] = useState([]);
  const [subscriptions, setSubscriptions] = useState([]);
  const [selectedPackageIndex, setSelectedPackageIndex] = useState(0);
  const { callAPI } = useAPI();

  const loadData = async () => {
    try {
      const response = await callAPI({
        url: `/subscriptions`,
        params: {
          user_id: supervisorId,
        },
      });
      setSubscriptions(response);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (subscriptions) {
      let result = [];
      if (type === "single") {
        result = subscriptions.filter(
          (subscription) => subscription.periodicity === "spot",
        );
      } else {
        result = subscriptions.filter(
          (subscription) => subscription.periodicity !== "spot",
        );
      }
      setPackages(result);
    }
  }, [subscriptions]);

  return (
    <IonModal className="BuySubscriptionModal" isOpen onDidDismiss={onDismiss}>
      <div className="BuySubscriptionModal_content">
        <h1>Acquista evento</h1>
        <Swiper
          className={classnames({
            "BuySubscriptionModal_swiper--single": packages.length === 1,
          })}
          pagination={{
            type: "fraction",
          }}
          navigation={true}
          onSlideChange={({ realIndex }) => setSelectedPackageIndex(realIndex)}
          modules={[Navigation, Pagination]}
        >
          {packages.map((pkg) => (
            <SwiperSlide key={pkg.id}>
              <SubscriptionTypeSlide data={pkg} />
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="BuySubscriptionModal_footer">
          <IonButton fill="clear" onClick={onDismiss}>
            Annulla
          </IonButton>
          <IonButton
            expand="full"
            color={"success"}
            onClick={() => {
              onConfirm(packages[selectedPackageIndex]);
              onDismiss();
            }}
          >
            Ok
          </IonButton>
        </div>
      </div>
    </IonModal>
  );
}

BuySubscriptionModal.propTypes = {
  type: PropTypes.oneOf(["single", "subscription"]),
  supervisorId: PropTypes.string,
  onDismiss: PropTypes.func,
  onConfirm: PropTypes.func,
};

export default BuySubscriptionModal;
