import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  IonButton,
  IonButtons,
  IonCheckbox,
  IonContent,
  IonHeader,
  IonItem,
  IonList,
  IonModal,
  IonTitle,
  IonToolbar,
} from "@ionic/react";

function AthletesModal({
  hideSelectedAthletes,
  isOpen,
  athletes,
  selectedAthletes,
  onDismiss,
  onConfirm,
}) {
  const [temporarySelectedAthletes, setTemporarySelectedAthletes] = useState(
    [],
  );

  useEffect(() => {
    if (isOpen) {
      setTemporarySelectedAthletes(selectedAthletes);
    }
  }, [isOpen]);

  const onSelectionChange = (e, athlete) => {
    const athleteId = athlete.id;
    if (e.detail.checked) {
      const selectedAthlete = athletes.find(
        (athlete) => athlete.id === athleteId,
      );
      setTemporarySelectedAthletes([
        ...temporarySelectedAthletes,
        selectedAthlete,
      ]);
    } else {
      setTemporarySelectedAthletes(
        temporarySelectedAthletes.filter((athlete) => athlete.id !== athleteId),
      );
    }
  };

  const onDismissModal = () => {
    onDismiss();
  };

  const onConfirmModal = () => {
    if (hideSelectedAthletes) {
      const selectedAthletesIds = selectedAthletes.map((a) => a.id);
      const newSelectedAthletes = temporarySelectedAthletes.filter(
        (a) => !selectedAthletesIds.includes(a.id),
      );
      onConfirm(newSelectedAthletes);
      return;
    }
    onConfirm(temporarySelectedAthletes);
  };

  return (
    <IonModal isOpen={isOpen} onDidDismiss={onDismiss}>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={onDismissModal}>Annulla</IonButton>
          </IonButtons>
          <IonTitle>Seleziona allievi</IonTitle>
          <IonButtons slot="end">
            <IonButton strong={true} onClick={onConfirmModal}>
              Conferma
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <IonList>
          {athletes &&
            athletes
              .sort((a, b) =>
                `${a.cognome} ${a.nome}`.localeCompare(
                  `${b.cognome} ${b.nome}`,
                ),
              )
              .map((athlete) => {
                const selected = temporarySelectedAthletes
                  .map((a) => a.id)
                  .includes(athlete.id);

                const isInSelected = selectedAthletes
                  .map((a) => a.id)
                  .includes(athlete.id);

                if (hideSelectedAthletes && isInSelected && selected) {
                  return null;
                }
                return (
                  <IonItem key={athlete.id}>
                    <IonCheckbox
                      labelPlacement="end"
                      justify="start"
                      checked={selected}
                      onIonChange={(e) => onSelectionChange(e, athlete)}
                    >
                      {`${athlete.cognome} ${athlete.nome}`}
                    </IonCheckbox>
                  </IonItem>
                );
              })}
        </IonList>
      </IonContent>
    </IonModal>
  );
}

AthletesModal.propTypes = {
  hideSelectedAthletes: PropTypes.bool,
  isOpen: PropTypes.bool,
  athletes: PropTypes.array,
  selectedAthletes: PropTypes.array,
  onDismiss: PropTypes.func,
  onConfirm: PropTypes.func,
};

export default AthletesModal;
