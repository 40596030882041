import { useState, useEffect } from "react";
import { getFirestore, doc, onSnapshot } from "firebase/firestore";

const useMatchSnapshot = (path) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const db = getFirestore();
    const docRef = doc(db, path);

    const unsubscribe = onSnapshot(
      docRef,
      (snapshot) => {
        if (snapshot.exists()) {
          setData({ id: snapshot.id, ...snapshot.data() });
        } else {
          setData(null);
        }
        setLoading(false);
      },
      (err) => {
        setError(err);
        setLoading(false);
      },
    );

    return () => unsubscribe(); // Cleanup on unmount
  }, [path]);

  return { data, loading, error };
};

export default useMatchSnapshot;
