import useAPI from "../basic/useAPI";

function useSaveOrUpdatePayment() {
  // const { firestoreDb } = useContext(FirebaseContext);
  const { callAPI } = useAPI();

  const saveOrUpdatePayment = async (payment) => {
    // remove hours, minutes, seconds and milliseconds from date
    payment.end_date.setHours(0);
    payment.end_date.setMinutes(0);
    payment.end_date.setSeconds(0);
    payment.end_date.setMilliseconds(0);

    payment.start_date.setHours(0);
    payment.start_date.setMinutes(0);
    payment.start_date.setSeconds(0);
    payment.start_date.setMilliseconds(0);

    // convert date to current timezone
    payment.end_date = new Date(
      payment.end_date.getTime() - payment.end_date.getTimezoneOffset() * 60000,
    );
    payment.start_date = new Date(
      payment.start_date.getTime() -
      payment.start_date.getTimezoneOffset() * 60000,
    );
    await callAPI({
      method: "post",
      url: "/payment-logs",
      data: payment,
    });
    // if (payment.id) {
    //   const paymentRef = doc(firestoreDb, "payment_logs", payment.id);
    //   await updateDoc(paymentRef, payment);
    // } else {
    //   const paymentRef = collection(firestoreDb, "payment_logs");
    //   await addDoc(paymentRef, payment);
    // }
  };

  return saveOrUpdatePayment;
}

export default useSaveOrUpdatePayment;
