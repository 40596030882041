import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  IonAlert,
  IonButton,
  IonButtons,
  IonCheckbox,
  IonContent,
  IonDatetime,
  IonFooter,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonSelect,
  IonSelectOption,
  IonText,
  IonTextarea,
  IonToolbar,
  useIonToast,
} from "@ionic/react";
import useSaveOrUpdatePayment from "../../hooks/subscriptions/useSaveOrUpdatePayment";
import useSubscriptions from "../../hooks/subscriptions/useSubscriptions";
import { AuthenticationContext } from "../../providers/AuthenticationProvider/AuthenticationProvider";
import useUserAppData from "../../hooks/users/useUserAppData";
import useAthletesByCoach from "../../hooks/useAthletesByCoach";
import useAPI from "../../hooks/basic/useAPI";
import { addCircleOutline, trashOutline } from "ionicons/icons";
import { FormattedDate } from "react-intl";

function SubscriptionEditModal({
  subscription,
  isOpen,
  onDismiss,
  onConfirm,
  userId,
}) {
  const [temporarySubscriptions, setTemporarySubscriptions] = useState(null);
  const [showFromCalendarModal, setShowFromCalendarModal] = useState(false);
  const [showToCalendarModal, setShowToCalendarModal] = useState(false);
  const [showPaymentDateModal, setShowPaymentDateModal] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(userId);
  const { appUserId } = useContext(AuthenticationContext);
  const [relatedGroups, setRelatedGroups] = useState([]);
  const [orphanGroups, setOrphanGroups] = useState([]);
  const [openDeleteAlert, setOpenDeleteAlert] = useState(false);

  const saveOrUpdatePayment = useSaveOrUpdatePayment();
  const { data: subscriptions } = useSubscriptions(appUserId);
  const { data: athletes } = useAthletesByCoach(appUserId);
  const { callAPI } = useAPI();
  const [present] = useIonToast();

  const getData = async () => {
    if (subscription) {
      const result = await callAPI({
        method: "get",
        url: `/groups-by-payment`,
        params: {
          paymentId: subscription.id,
        },
      });

      setRelatedGroups(result);

      const orphanGroupsResult = await callAPI({
        method: "get",
        url: `/orphan-groups`,
        params: {
          userId: subscription.user_id,
        },
      });

      setOrphanGroups(orphanGroupsResult);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (isOpen && !subscription) {
      setTemporarySubscriptions({
        id: null,
        start_date: new Date(),
        end_date: new Date(),
        paid: false,
        payment_date: null,
        payment_channel: "",
        payment_note: "",
        subscription_id: null,
      });
    }
  }, [isOpen, subscription]);

  useEffect(() => {
    if (isOpen && subscription) {
      setTemporarySubscriptions({
        ...subscription,
        start_date: new Date(subscription.start_date._seconds * 1000),
        end_date: new Date(subscription.end_date._seconds * 1000),
        payment_date: subscription.payment_date
          ? new Date(subscription.payment_date._seconds * 1000)
          : null,
      });
    }
  }, [isOpen, subscription]);

  const onDismissModal = () => {
    onDismiss();
  };

  const deletePackageLog = async () => {
    try {
      await callAPI({
        url: `/payment-logs`,
        method: "DELETE",
        params: {
          id: subscription.id,
        },
      });
      present({
        message: "Gruppo eliminato correttamente",
        duration: 3000,
        color: "success",
      });
      onConfirm();
    } catch (error) {
      console.error(error);
      present({
        message: "Errore durante l'eliminazione del gruppo",
        duration: 3000,
        color: "danger",
      });
    }
  };

  const onUpdate = async () => {
    try {
      await callAPI({
        method: "put",
        url: `/payment-and-grous-logs`,
        data: {
          ...temporarySubscriptions,
          groups_logs: relatedGroups,
        },
      });
      present({
        message: "Pacchetto aggiornato correttamente",
        duration: 3000,
        color: "success",
      });
    } catch (error) {
      present({
        message: "Errore durante l'aggiornamento del pacchetto",
        duration: 3000,
        color: "danger",
      });
      console.error(error);
    }
  };

  const onSave = async () => {
    if (subscription) {
      await onUpdate();
    } else {
      const selectedSubscription = subscriptions.find(
        (s) => s.id === temporarySubscriptions.subscription_id,
      );
      const userData = athletes.find((a) => a.id === selectedUserId);
      await saveOrUpdatePayment({
        description: selectedSubscription.name,
        end_date: temporarySubscriptions.end_date,
        groups: selectedSubscription.groups || null,
        start_date: temporarySubscriptions.start_date,
        paid: temporarySubscriptions.paid,
        payment_channel: temporarySubscriptions.payment_channel,
        payment_date: temporarySubscriptions.payment_date,
        payment_note: temporarySubscriptions.payment_note,
        periodicity: selectedSubscription.periodicity || null,
        subscription_id: temporarySubscriptions.subscription_id,
        supervisor_id: appUserId,
        type: selectedSubscription.type || null,
        user_id: selectedUserId,
        user_full_name: userData.fullName,
      });
    }
    onConfirm();
  };

  if (!temporarySubscriptions) {
    return null;
  }

  const {
    id,
    description,
    paid,
    payment_channel,
    payment_note,
    subscription_id,
  } = temporarySubscriptions;
  const fromDate = temporarySubscriptions.start_date;
  const toDate = temporarySubscriptions.end_date;
  const paymentDate = temporarySubscriptions.payment_date;

  return (
    <IonModal isOpen={isOpen} onDidDismiss={() => onDismissModal()}>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={onDismissModal}>Annulla</IonButton>
          </IonButtons>
          <IonButtons slot="end">
            <IonButton strong={true} onClick={onSave}>
              Conferma
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <IonList>
          {id && (
            <IonItem>
              <IonInput
                className="ion-text-right"
                label="Tipologia"
                name="description"
                value={description}
                placeholder="Tipologia"
                disabled
              ></IonInput>
            </IonItem>
          )}
          {!id && (
            <IonItem>
              <IonLabel className="custom-label">
                Seleziona un pacchetto
              </IonLabel>
              <IonSelect
                aria-label="Seleziona un pacchetto"
                label=""
                interface="action-sheet"
                placeholder="Seleziona un pacchetto"
                value={subscription_id}
                onIonChange={(e) => {
                  setTemporarySubscriptions({
                    ...temporarySubscriptions,
                    subscription_id: e.detail.value,
                  });
                }}
              >
                {subscriptions &&
                  subscriptions.map((subscriptions) => (
                    <IonSelectOption
                      key={subscriptions.id}
                      value={subscriptions.id}
                    >
                      {subscriptions.name}
                    </IonSelectOption>
                  ))}
              </IonSelect>
            </IonItem>
          )}
          <IonItem onClick={() => setShowFromCalendarModal(true)}>
            <IonLabel>Valido dal</IonLabel>
            <div>
              {/* Mostra la data selezionata o un trattino se non selezionata */}
              {fromDate.toLocaleDateString()}
            </div>

            {/* Modale per la selezione della data */}
          </IonItem>
          <IonModal
            isOpen={showFromCalendarModal}
            onDidDismiss={() => setShowFromCalendarModal(false)}
          >
            <IonDatetime
              className="EditUserDatePicker"
              presentation="date"
              value={fromDate.toISOString()}
              onIonChange={
                // handleDateChange
                (e) => {
                  setTemporarySubscriptions({
                    ...temporarySubscriptions,
                    start_date: new Date(e.detail.value),
                  });
                }
              }
            />
            <IonButton onClick={() => setShowFromCalendarModal(false)}>
              Chiudi
            </IonButton>
          </IonModal>
          <IonItem onClick={() => setShowToCalendarModal(true)}>
            <IonLabel>Valido al</IonLabel>
            <div>
              {/* Mostra la data selezionata o un trattino se non selezionata */}
              {toDate.toLocaleDateString()}
            </div>

            {/* Modale per la selezione della data */}
          </IonItem>
          <IonModal
            isOpen={showToCalendarModal}
            onDidDismiss={() => setShowToCalendarModal(false)}
          >
            <IonDatetime
              className="EditUserDatePicker"
              presentation="date"
              value={toDate.toISOString()}
              onIonChange={
                // handleDateChange
                (e) => {
                  setTemporarySubscriptions({
                    ...temporarySubscriptions,
                    end_date: new Date(e.detail.value),
                  });
                }
              }
            />
            <IonButton onClick={() => setShowToCalendarModal(false)}>
              Chiudi
            </IonButton>
          </IonModal>
          <IonItem>
            <IonCheckbox
              className="ion-text-right"
              label="Pagato"
              name="pagato"
              checked={paid}
              justify="space-between"
              type="checkbox"
              placeholder="Descrizione"
              onIonChange={(e) => {
                const newObject = {
                  ...temporarySubscriptions,
                  paid: e.detail.checked,
                };

                if (!e.detail.checked) {
                  newObject.payment_date = null;
                }

                setTemporarySubscriptions(newObject);
              }}
            >
              <IonLabel>Pagato</IonLabel>
            </IonCheckbox>
          </IonItem>
          <IonItem onClick={() => setShowPaymentDateModal(true)}>
            <IonLabel>Data pagamento</IonLabel>
            <div>{paymentDate ? paymentDate.toLocaleDateString() : "-"}</div>

            {/* Modale per la selezione della data */}
          </IonItem>
          <IonModal
            isOpen={showPaymentDateModal}
            onDidDismiss={() => setShowPaymentDateModal(false)}
          >
            <IonDatetime
              className="EditUserDatePicker"
              presentation="date"
              value={paymentDate ? paymentDate.toISOString() : ""}
              onIonChange={
                // handleDateChange
                (e) => {
                  debugger;
                  setTemporarySubscriptions({
                    ...temporarySubscriptions,
                    payment_date: new Date(e.detail.value),
                  });
                }
              }
            />
            <IonButton onClick={() => setShowPaymentDateModal(false)}>
              Chiudi
            </IonButton>
          </IonModal>
          <IonItem>
            <IonInput
              className="ion-text-right"
              label="Tipo pagamento"
              name="payment"
              value={temporarySubscriptions.payment_channel}
              placeholder="Tipo pagamento"
              onIonInput={(e) => {
                setTemporarySubscriptions({
                  ...temporarySubscriptions,
                  payment_channel: e.detail.value,
                });
              }}
            ></IonInput>
          </IonItem>
          <IonItem>
            <IonTextarea
              className="ion-text-right"
              label="Note"
              name="note"
              value={temporarySubscriptions.payment_note}
              onIonInput={(e) => {
                setTemporarySubscriptions({
                  ...temporarySubscriptions,
                  payment_note: e.detail.value,
                });
              }}
              placeholder="Note"
            ></IonTextarea>
          </IonItem>
        </IonList>
        <IonList>
          {relatedGroups
            .sort((a, b) => a.from_date._seconds - b.from_date._seconds)
            .map((lessonLog) => (
              <IonItem key={lessonLog.id}>
                <IonIcon
                  icon={trashOutline}
                  slot="end"
                  onClick={() => {
                    // remove group from related groups and add it to orphan groups
                    setRelatedGroups(
                      relatedGroups.filter((l) => l.id !== lessonLog.id),
                    );
                    setOrphanGroups([...orphanGroups, lessonLog]);
                  }}
                ></IonIcon>
                <IonLabel style={{ display: "flex" }}>
                  {lessonLog.description}
                  <span
                    style={{
                      marginLeft: "auto",
                      fontWeight: "normal",
                    }}
                  >
                    <FormattedDate
                      value={lessonLog.from_date._seconds * 1000}
                      day="2-digit"
                      month="2-digit"
                      year="2-digit"
                    />
                  </span>
                </IonLabel>
              </IonItem>
            ))}
        </IonList>
        {orphanGroups.length > 0 && (
          <IonText>
            <h4>Gruppi senza pacchetto</h4>
          </IonText>
        )}
        <IonList>
          {orphanGroups
            .sort((a, b) => a.from_date._seconds - b.from_date._seconds)
            .map((lessonLog) => (
              <IonItem key={lessonLog.id}>
                <IonIcon
                  icon={addCircleOutline}
                  slot="end"
                  onClick={() => {
                    // remove from orphan groups and add it to related groups
                    setOrphanGroups(
                      orphanGroups.filter((l) => l.id !== lessonLog.id),
                    );
                    setRelatedGroups([...relatedGroups, lessonLog]);
                  }}
                ></IonIcon>
                <IonLabel style={{ display: "flex" }}>
                  {lessonLog.description}
                  <span
                    style={{
                      marginLeft: "auto",
                      fontWeight: "normal",
                    }}
                  >
                    <FormattedDate
                      value={lessonLog.from_date._seconds * 1000}
                      day="2-digit"
                      month="2-digit"
                      year="2-digit"
                    />
                  </span>
                </IonLabel>
              </IonItem>
            ))}
        </IonList>
        <IonAlert
          isOpen={openDeleteAlert}
          header="Vuoi cancellare il pacchetto?"
          trigger="present-alert"
          buttons={[
            {
              text: "Annulla",
              role: "cancel",
              handler: async () => { },
            },
            {
              text: "Conferma",
              role: "confirm",
              handler: async () => {
                await deletePackageLog();
              },
            },
          ]}
          onDidDismiss={({ detail }) =>
            console.log(`Dismissed with role: ${detail.role}`)
          }
        ></IonAlert>
      </IonContent>
      <IonFooter className="ion-no-border ion-padding">
        <IonButton
          color="danger"
          expand="block"
          size="large"
          onClick={() => setOpenDeleteAlert(true)}
        >
          Cancella
        </IonButton>
      </IonFooter>
    </IonModal>
  );
}

SubscriptionEditModal.propTypes = {
  hideSelectedAthletes: PropTypes.bool,
  isOpen: PropTypes.bool,
  athletes: PropTypes.array,
  selectedAthletes: PropTypes.array,
  onDismiss: PropTypes.func,
  onConfirm: PropTypes.func,
  subscription: PropTypes.shape({
    id: PropTypes.string,
    start_date: PropTypes.shape({ seconds: PropTypes.number }),
    end_date: PropTypes.shape({ seconds: PropTypes.number }),
    participants: PropTypes.array,
    fieldName: PropTypes.string,
    clubName: PropTypes.string,
    label: PropTypes.string,
    description: PropTypes.string,
    paid: PropTypes.bool,
    payment_date: PropTypes.shape({ seconds: PropTypes.number }),
    payment_channel: PropTypes.string,
  }),
};

export default SubscriptionEditModal;
