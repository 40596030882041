import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  IonButton,
  IonContent,
  IonFooter,
  IonHeader,
  IonImg,
  IonItem,
  IonList,
  IonModal,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";
import useAPI from "../../hooks/basic/useAPI";
import { useHistory } from "react-router";

function NewMatchModal({ athletes, isOpen, onDidDismiss, booking }) {
  const [matchType, setMatchType] = useState("single");
  const [firstAthlete, setFirstAthlete] = useState(null);
  const [secondAthlete, setSecondAthlete] = useState(null);
  const [matchMode, setMatchMode] = useState("2set_tiebreak");
  const { callAPI } = useAPI();
  const history = useHistory();

  useEffect(() => {
    if (athletes.length > 0) {
      setFirstAthlete(athletes[0]);
      if (athletes.length > 1) {
        setSecondAthlete(athletes[1]);
      }
    }
  }, []);

  const validFirstAthletes = athletes.filter(
    (athlete) => athlete.id !== secondAthlete?.id,
  );

  const validSecondAthletes = athletes.filter(
    (athlete) => athlete.id !== firstAthlete?.id,
  );

  const saveNewMatch = async () => {
    // const { supervisorId, type, mode, bookingId, team_a, team_b } = req.body;
    // url: `/delete-group`,
    //     method: "DELETE",
    //     params: {
    //       id: subscription.id,
    //     },

    const matchId = await callAPI({
      url: "/match",
      method: "POST",
      data: {
        supervisorId: booking.relatedSupervisorId,
        type: matchType,
        mode: matchMode,
        bookingId: booking.id,
        team_a: [
          {
            athleteId: firstAthlete.id,
            name: firstAthlete.fullName,
          },
        ],
        team_b: [
          {
            athleteId: secondAthlete.id,
            name: secondAthlete.fullName,
          },
        ],
      },
    });

    onDidDismiss();
    history.push(`/match/${matchId}`);
  };

  return (
    <IonModal
      className=""
      zIndex="80000"
      style={{ zIndex: 9999 }}
      isOpen={isOpen}
      onDidDismiss={onDidDismiss}
    >
      <IonHeader className="no-border">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "env(safe-area-inset-top)", // Aggiunge spazio su iPhone
            textAlign: "center",
          }}
        >
          <IonImg
            src="notification.png"
            style={{
              width: "55px",
              paddingTop: "1rem",
              marginBottom: "0.5rem",
            }}
          />
          <h3
            style={{
              fontWeight: "bold",
              color: "#062a40",
            }}
          >
            Nuova partita
          </h3>
        </div>
      </IonHeader>
      <IonContent className="ion-padding" style={{}}>
        <IonList>
          <IonItem>
            <IonSelect
              label="Tipologia"
              placeholder="Tipologia partita"
              onIonChange={(e) => setMatchType(e.detail.value)}
              value={matchType}
            >
              <IonSelectOption value="single">Singolare</IonSelectOption>
              <IonSelectOption value="double">Doppio</IonSelectOption>
            </IonSelect>
          </IonItem>
          <IonItem>
            <IonSelect
              label="Giocatore 1"
              placeholder="Giocatore 1"
              onIonChange={(e) => {
                const athlete = athletes.find(
                  (athlete) => athlete.userId === e.detail.value,
                );
                setFirstAthlete(athlete);
              }}
              value={firstAthlete?.userId}
            >
              {validFirstAthletes.map((athlete) => (
                <IonSelectOption key={athlete.userId} value={athlete.userId}>
                  {athlete.fullName}
                </IonSelectOption>
              ))}
            </IonSelect>
          </IonItem>
          <IonItem>
            <IonSelect
              label="Giocatore 2"
              placeholder="Giocatore 2"
              onIonChange={(e) => {
                const athlete = athletes.find(
                  (athlete) => athlete.userId === e.detail.value,
                );
                setSecondAthlete(athlete);
              }}
              value={secondAthlete?.userId}
            >
              {validSecondAthletes.map((athlete) => (
                <IonSelectOption key={athlete.userId} value={athlete.userId}>
                  {athlete.fullName}
                </IonSelectOption>
              ))}
            </IonSelect>
          </IonItem>
          <IonItem>
            <IonSelect
              label="Modalità"
              placeholder="Modalità"
              onIonChange={(e) => setMatchMode(e.detail.value)}
              value={matchMode}
            >
              <IonSelectOption value="2set_tiebreak">
                2 set + tie break
              </IonSelectOption>
              <IonSelectOption value="best_of_3">
                Al meglio di 3
              </IonSelectOption>
              <IonSelectOption value="best_of_5">
                Al meglio di 5
              </IonSelectOption>
            </IonSelect>
          </IonItem>
        </IonList>
      </IonContent>
      <IonFooter className="no-border ion-padding">
        <IonButton
          className="ion-margin-bottom"
          expand="block"
          size="large"
          color="success"
          onClick={saveNewMatch}
        >
          Crea
        </IonButton>
        <IonButton
          expand="block"
          size="large"
          color="danger"
          onClick={onDidDismiss}
        >
          Annulla
        </IonButton>
      </IonFooter>
    </IonModal>
  );
}

NewMatchModal.propTypes = {
  athletes: PropTypes.array.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onDidDismiss: PropTypes.func.isRequired,
  booking: PropTypes.object.isRequired,
};

export default NewMatchModal;
