import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Virtuoso } from "react-virtuoso";
import {
  IonDatetime,
  IonDatetimeButton,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonRefresher,
  IonRefresherContent,
} from "@ionic/react";
import SubscriptionEditModal from "../../../../components/SubscriptionEditModal/SubscriptionEditModal";
import CardListHeader from "../../../../components/CardListHeader/CardListHeader";
import LessonCard from "./LessonCard";
import useAPI from "../../../../hooks/basic/useAPI";

const fromDateDefault = new Date();
fromDateDefault.setDate(1);
// create a variable toDate that contains the first day of the next month
const toDateDefault = new Date(fromDateDefault);
toDateDefault.setMonth(toDateDefault.getMonth() + 1);

function LessonsContent({
  coachId,
  userId,
  showHeader = true,
  canAddSubscription = true,
  defaultFilterValue = "Non Pagati",
}) {
  const [fromDate, setFromDate] = useState(fromDateDefault);
  const [toDate, setToDate] = useState(toDateDefault);
  const [showFromDateModal, setShowFromDateModal] = useState(false);
  const [showToDateModal, setShowToDateModal] = useState(false);

  const { callAPI } = useAPI();

  const handleRefresh = (e) => {
    loadData();
    e.detail.complete();
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const [athletesDictionary, setAthletesDictionary] = useState({});
  const [selectedFilterValue, setSelectedFilterValue] =
    useState(defaultFilterValue);
  const [composedData, setComposedData] = useState([]);

  const filteredData = composedData?.filter((subscription) => {
    return true;
  });

  const loadData = async () => {
    let normalizedStartDate = new Date(fromDate);
    normalizedStartDate.setHours(0);
    normalizedStartDate.setMinutes(0);
    normalizedStartDate.setSeconds(0);
    normalizedStartDate.setMilliseconds(0);

    let normalizedEndDate = new Date(toDate);
    normalizedEndDate.setHours(0);
    normalizedEndDate.setMinutes(0);
    normalizedEndDate.setSeconds(0);
    normalizedEndDate.setMilliseconds(0);

    normalizedEndDate = new Date(
      normalizedEndDate.getTime() -
      normalizedEndDate.getTimezoneOffset() * 60000,
    );
    normalizedStartDate = new Date(
      normalizedStartDate.getTime() -
      normalizedStartDate.getTimezoneOffset() * 60000,
    );

    const data = await callAPI({
      url: "/groups-by-coach",
      params: {
        coachId,
        fromDate: normalizedStartDate,
        toDate: normalizedEndDate,
      },
      preventCall: !coachId,
    });

    const allAthletes = await callAPI({
      url: "/athletes-by-coach",
      params: {
        coachId,
      },
      preventCall: !coachId,
    });

    if (allAthletes) {
      const dictionary = allAthletes.reduce((acc, curr) => {
        acc[curr.id] = curr;
        return acc;
      }, {});
      setAthletesDictionary(dictionary);
      for (const subscription of data) {
        const athlete = dictionary[subscription.user_id];
        subscription.athlete = athlete;
      }
    }
    setComposedData(data);
  };

  useEffect(() => {
    if (coachId) {
      loadData();
    }
  }, [coachId, fromDate, toDate]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
        <IonRefresherContent></IonRefresherContent>
      </IonRefresher>
      <h1
        style={{
          color: "#062a40",
          padding: "0 20px",
        }}
      >
        Registro lezioni
      </h1>
      <IonList>
        <IonItem>
          <IonLabel>Data Inizio</IonLabel>
          <IonDatetimeButton
            datetime="calendarFromDate"
            onClick={() => setShowFromDateModal(true)}
          ></IonDatetimeButton>
          <IonModal
            keepContentsMounted={true}
            onIonModalDidDismiss={() => setShowFromDateModal(false)}
            isOpen={showFromDateModal}
          >
            <IonDatetime
              presentation="date"
              id="calendarFromDate"
              value={fromDate.toISOString()}
              onIonChange={(e) => {
                setShowFromDateModal(false);
                setFromDate(new Date(e.detail.value));
              }}
            ></IonDatetime>
          </IonModal>
        </IonItem>
        <IonItem>
          <IonLabel>Data Fine</IonLabel>
          <IonDatetimeButton
            datetime="calendarToDate"
            onClick={() => setShowToDateModal(true)}
          ></IonDatetimeButton>
          <IonModal
            keepContentsMounted={true}
            onIonModalDidDismiss={() => setShowToDateModal(false)}
            isOpen={showToDateModal}
          >
            <IonDatetime
              id="calendarToDate"
              presentation="date"
              value={toDate.toISOString()}
              onIonChange={(e) => {
                setShowToDateModal(false);
                setToDate(new Date(e.detail.value));
              }}
            ></IonDatetime>
          </IonModal>
        </IonItem>
      </IonList>
      {showHeader && (
        <CardListHeader
          filterOptions={{
            defaultSelectedOption: selectedFilterValue,
            options: ["Tutti", "Non Pagati"],
          }}
          canAddSubscription={canAddSubscription}
          onAddClick={() => {
            if (!coachId) return;
            setSelectedSubscription(null);
            setIsModalOpen(true);
          }}
          onFilterChange={(option) => {
            setSelectedFilterValue(option);
          }}
        />
      )}
      <div
        style={{
          flex: 1,
        }}
      >
        {filteredData && (
          <Virtuoso
            style={{ height: "100%" }}
            totalCount={filteredData.length}
            itemContent={(index) => (
              <LessonCard
                key={filteredData[index].id}
                data={filteredData[index]}
              />
            )}
          />
        )}
      </div>
      {isModalOpen && (
        <SubscriptionEditModal
          isOpen={isModalOpen}
          subscription={selectedSubscription}
          userId={userId}
          onDismiss={() => setIsModalOpen(false)}
          onConfirm={() => {
            setIsModalOpen(false);
          }}
        />
      )}
    </div>
  );
}

LessonsContent.propTypes = {
  canAddSubscription: PropTypes.bool,
  coachId: PropTypes.string.isRequired,
  showHeader: PropTypes.bool,
  userId: PropTypes.string.isRequired,
};

export default LessonsContent;
