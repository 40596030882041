import React from "react";
import PropTypes from "prop-types";

function CurrentPlayerIndicator({ color = "green" }) {
  return (
    <div
      className="heartbeat  "
      style={{
        width: "10px",
        height: "10px",
        borderRadius: "50%",
        backgroundColor: color,
      }}
    ></div>
  );
}

CurrentPlayerIndicator.propTypes = {};

export default CurrentPlayerIndicator;
