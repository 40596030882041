import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  IonButton,
  IonContent,
  IonFooter,
  IonHeader,
  IonImg,
  IonModal,
  IonText,
  IonTitle,
} from "@ionic/react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

import "./styles/ChangeLogModal.scss";

function checkIfCurrentVersionIsNewer(currentVersion, latestVersion) {
  const currentVersionArray = currentVersion.split(".");
  const latestVersionArray = latestVersion.split(".");
  for (let i = 0; i < currentVersionArray.length; i++) {
    if (parseInt(currentVersionArray[i]) < parseInt(latestVersionArray[i])) {
      return true;
    }
  }
  return false;
}

const latestVersion = "1.0.2";

function ChangeLogModal(props) {
  // get the current version from the local storage
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const currentVersion = localStorage.getItem("version");
    if (currentVersion) {
      if (checkIfCurrentVersionIsNewer(currentVersion, latestVersion)) {
        setShowModal(true);
      }
    } else {
      setShowModal(true);
    }
  }, []);

  const accept = () => {
    localStorage.setItem("version", latestVersion);
    setShowModal(false);
  };

  if (!showModal) {
    return null;
  }

  return (
    <IonModal
      className="ChangeLogModal"
      zIndex="80000"
      style={{ zIndex: 9999 }}
      isOpen={showModal}
      onDidDismiss={() => setShowModal(false)}
    >
      <IonHeader className="no-border">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "env(safe-area-inset-top)", // Aggiunge spazio su iPhone
            textAlign: "center",
          }}
        >
          <IonImg
            src="notification.png"
            style={{
              width: "55px",
              paddingTop: "1rem",
              marginBottom: "0.5rem",
            }}
          />
          <h3
            style={{
              fontWeight: "bold",
              color: "#062a40",
            }}
          >
            Novità
          </h3>
        </div>
      </IonHeader>
      <IonContent className="ion-padding" style={{}}>
        <IonText>
          <h4
            style={{
              display: "flex",
            }}
          >
            <FontAwesomeIcon icon={faCheck} />
            &nbsp;
            <span>Recupero lezioni</span>
          </h4>
        </IonText>
        <IonText>
          <h4
            style={{
              display: "flex",
            }}
          >
            <FontAwesomeIcon icon={faCheck} />
            &nbsp;<span>Acquisto lezioni singole</span>
          </h4>
        </IonText>
        <IonText>
          <h4
            style={{
              display: "flex",
            }}
          >
            <FontAwesomeIcon icon={faCheck} />
            &nbsp;<span>Cancellazione fino a 24 ore dall&#39; evento</span>
          </h4>
        </IonText>
        <IonText>
          <h4
            style={{
              display: "flex",
            }}
          >
            <FontAwesomeIcon icon={faCheck} />
            &nbsp;<span>Notifica scadenza iscrizione</span>
          </h4>
        </IonText>
      </IonContent>
      <IonFooter className="no-border ion-padding">
        <IonButton expand="block" size="large" color="success" onClick={accept}>
          Chiudi
        </IonButton>
      </IonFooter>
    </IonModal>
  );
}

ChangeLogModal.propTypes = {};

export default ChangeLogModal;
