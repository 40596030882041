import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import useSubscriptionsByUserAndCoach from "../../../../hooks/subscriptions/useSubscriptionsByUserAndCoach";
import SubscriptionCard from "./SubscriptionCard";
import {
  IonActionSheet,
  IonButton,
  IonDatetime,
  IonDatetimeButton,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonRefresher,
  IonRefresherContent,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";
import SubscriptionEditModal from "../../../../components/SubscriptionEditModal/SubscriptionEditModal";
import CardListHeader from "../../../../components/CardListHeader/CardListHeader";
import useNotPaidGroupLogsByUserAndCoach from "../../../../hooks/groupLogs/useNotPaidGroupLogsByUserAndCoach";
import GroupLogCard from "./GroupLogCard";
import BuySubscriptionModal from "../../../../components/BuySubscriptionModal/BuySubscriptionModal";
import { Virtuoso } from "react-virtuoso";
import useAPI from "../../../../hooks/basic/useAPI";
import GroupEditModal from "../../../../components/GroupEditModal/GroupEditModal";

const fromDateDefault = new Date();
fromDateDefault.setDate(1);
// create a variable toDate that contains the first day of the next month
const toDateDefault = new Date(fromDateDefault);
toDateDefault.setMonth(toDateDefault.getMonth() + 1);

function GroupsContent({
  coachId,
  userId,
  showHeader = true,
  canAddSubscription = true,
  defaultFilterValue = "Non Pagati",
}) {
  const { callAPI } = useAPI();
  const [data, setData] = useState([]);

  const [selectedGroup, setSelectedGroup] = useState(null);
  const handleRefresh = (e) => {
    loadData();
    e.detail.complete();
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [fromDate, setFromDate] = useState(fromDateDefault);
  const [toDate, setToDate] = useState(toDateDefault);
  const [showFromDateModal, setShowFromDateModal] = useState(false);
  const [showToDateModal, setShowToDateModal] = useState(false);
  const [filterValue, setFilterValue] = useState("");

  function isSubscriptionActive(from_date, to_date) {
    const now = new Date();
    return (
      now >= new Date(from_date.seconds * 1000) &&
      now <= new Date(to_date.seconds * 1000)
    );
  }

  const loadData = async () => {
    let normalizedStartDate = new Date(fromDate);
    normalizedStartDate.setHours(0);
    normalizedStartDate.setMinutes(0);
    normalizedStartDate.setSeconds(0);
    normalizedStartDate.setMilliseconds(0);

    let normalizedEndDate = new Date(toDate);
    normalizedEndDate.setHours(0);
    normalizedEndDate.setMinutes(0);
    normalizedEndDate.setSeconds(0);
    normalizedEndDate.setMilliseconds(0);

    normalizedEndDate = new Date(
      normalizedEndDate.getTime() -
      normalizedEndDate.getTimezoneOffset() * 60000,
    );
    normalizedStartDate = new Date(
      normalizedStartDate.getTime() -
      normalizedStartDate.getTimezoneOffset() * 60000,
    );

    const data = await callAPI({
      url: "/groups-by-coach",
      params: {
        coachId,
        fromDate: normalizedStartDate,
        toDate: normalizedEndDate,
        paid: false,
      },
      preventCall: !coachId,
    });

    setData(data);
  };

  useEffect(() => {
    if (coachId) {
      loadData();
    }
  }, [coachId, fromDate, toDate]);

  const sortedWithCurrentSubscriptionOnTop = data
    ?.filter(
      (a) =>
        filterValue === "" ||
        a.full_name.toLowerCase().indexOf(filterValue.toLowerCase()) !== -1,
    )
    .sort((a) => {
      if (isSubscriptionActive(a.from_date, a.to_date)) {
        return -1;
      }
      return 1;
    });

  return (
    <div
      style={{
        height: "calc(100% - 50px)",
      }}
    >
      <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
        <IonRefresherContent></IonRefresherContent>
      </IonRefresher>
      <IonList>
        <IonItem>
          <IonLabel>Data Inizio</IonLabel>
          <IonDatetimeButton
            datetime="calendarFromDate"
            onClick={() => setShowFromDateModal(true)}
          ></IonDatetimeButton>
          <IonModal
            keepContentsMounted={true}
            onIonModalDidDismiss={() => setShowFromDateModal(false)}
            isOpen={showFromDateModal}
          >
            <IonDatetime
              presentation="date"
              id="calendarFromDate"
              value={fromDate.toISOString()}
              onIonChange={(e) => {
                setShowFromDateModal(false);
                setFromDate(new Date(e.detail.value));
              }}
            ></IonDatetime>
          </IonModal>
        </IonItem>
        <IonItem>
          <IonLabel>Data Fine</IonLabel>
          <IonDatetimeButton
            datetime="calendarToDate"
            onClick={() => setShowToDateModal(true)}
          ></IonDatetimeButton>
          <IonModal
            keepContentsMounted={true}
            onIonModalDidDismiss={() => setShowToDateModal(false)}
            isOpen={showToDateModal}
          >
            <IonDatetime
              id="calendarToDate"
              presentation="date"
              value={toDate.toISOString()}
              onIonChange={(e) => {
                setShowToDateModal(false);
                setToDate(new Date(e.detail.value));
              }}
            ></IonDatetime>
          </IonModal>
        </IonItem>
        <IonItem>
          <IonLabel>Atleta</IonLabel>
          <IonInput
            style={{ textAlign: "right" }}
            value={filterValue}
            onIonInput={(e) => {
              setFilterValue(e.detail.value);
            }}
          ></IonInput>
        </IonItem>
      </IonList>
      {sortedWithCurrentSubscriptionOnTop && (
        <Virtuoso
          style={{ height: "100%" }}
          totalCount={sortedWithCurrentSubscriptionOnTop.length}
          itemContent={(index) => (
            <div
              key={sortedWithCurrentSubscriptionOnTop[index].id}
              onClick={() => {
                setSelectedGroup(sortedWithCurrentSubscriptionOnTop[index]);
                setIsModalOpen(true);
              }}
            >
              <GroupLogCard
                key={sortedWithCurrentSubscriptionOnTop[index].id}
                data={sortedWithCurrentSubscriptionOnTop[index]}
              />
            </div>
          )}
        />
      )}
      {/* {data &&
        data.map((subscription) => (
          <div
            key={subscription.id}
            onClick={() => {
              // setSelectedSubscription(subscription);
              // setIsModalOpen(true);
            }}
          >
            <GroupLogCard key={subscription.id} data={subscription} />
          </div>
        ))} */}
      {isModalOpen && coachId && (
        <GroupEditModal
          isOpen={isModalOpen}
          subscription={selectedGroup}
          userId={userId}
          onDismiss={() => setIsModalOpen(false)}
          onConfirm={() => {
            setIsModalOpen(false);
            loadData();
          }}
        />
      )}
    </div>
  );
}

GroupsContent.propTypes = {
  canAddSubscription: PropTypes.bool,
  coachId: PropTypes.string.isRequired,
  showHeader: PropTypes.bool,
  userId: PropTypes.string.isRequired,
};

export default GroupsContent;
