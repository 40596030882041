import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  IonAlert,
  IonButton,
  IonButtons,
  IonCheckbox,
  IonContent,
  IonDatetime,
  IonFooter,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonSelect,
  IonSelectOption,
  IonText,
  IonTextarea,
  IonToolbar,
  useIonToast,
} from "@ionic/react";
import useAPI from "../../hooks/basic/useAPI";
import { addCircleOutline, trashOutline } from "ionicons/icons";
import { FormattedDate } from "react-intl";

function SubscriptionEditModal({
  subscription,
  isOpen,
  onDismiss,
  onConfirm,
  userId,
}) {
  const [present] = useIonToast();
  const [showFromCalendarModal, setShowFromCalendarModal] = useState(false);
  const [showToCalendarModal, setShowToCalendarModal] = useState(false);
  const [openDeleteAlert, setOpenDeleteAlert] = useState(false);
  const [fromDate, setFromDate] = useState(
    new Date(subscription.from_date._seconds * 1000),
  );
  const [toDate, setToDate] = useState(
    new Date(subscription.to_date._seconds * 1000),
  );
  const [minimumLessons, setMinimumLessons] = useState(
    subscription.minimum_lessons,
  );
  const [description, setDescription] = useState(subscription.description);
  const [lessonLogs, setLessonLogs] = useState([]);
  const [orphanLessons, setOrphanLessons] = useState([]);

  const { callAPI } = useAPI();

  useEffect(() => {
    getLessonsLogs();
    getOrphanLessons();
  }, []);

  const onDismissModal = () => {
    onDismiss();
  };

  const getLessonsLogs = async () => {
    const response = await callAPI({
      url: `/lesson-logs-by-group-log-id`,
      params: {
        group_log_id: subscription.id,
      },
    });

    setLessonLogs(response);
  };

  const getOrphanLessons = async () => {
    const response = await callAPI({
      url: `/orphan-lessons-logs`,
      params: {
        user_id: subscription.user_id,
      },
    });

    console.log(response);
    setOrphanLessons(response);
  };

  const deleteGroupLog = async () => {
    try {
      await callAPI({
        url: `/delete-group`,
        method: "DELETE",
        params: {
          id: subscription.id,
        },
      });
      present({
        message: "Gruppo eliminato correttamente",
        duration: 3000,
        color: "success",
      });
      onConfirm();
    } catch (error) {
      console.error(error);
      present({
        message: "Errore durante l'eliminazione del gruppo",
        duration: 3000,
        color: "danger",
      });
    }
  };

  const updateGroupsAndLessons = async () => {
    try {
      await callAPI({
        url: `/update-group-and-lessons`,
        method: "PUT",
        data: {
          id: subscription.id,
          from_date: fromDate,
          to_date: toDate,
          minimum_lessons: minimumLessons,
          lesson_logs: lessonLogs,
          description,
        },
      });
      present({
        message: "Gruppo aggiornato correttamente",
        duration: 3000,
        color: "success",
      });
      onConfirm();
    } catch (error) {
      console.error(error);
      present({
        message: "Errore durante l'aggiornamento del gruppo",
        duration: 3000,
        color: "danger",
      });
    }
  };

  const { full_name } = subscription;

  return (
    <IonModal isOpen={true} onDidDismiss={() => onDismissModal()}>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={onDismissModal}>Annulla</IonButton>
          </IonButtons>
          <IonButtons slot="end">
            <IonButton strong={true} onClick={updateGroupsAndLessons}>
              Salva
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <IonList>
          <IonItem>
            <IonInput
              className="ion-text-right"
              label="Descrizione"
              name="description"
              value={description}
              placeholder="Descrizione"
              onIonInput={(e) => setDescription(e.detail.value)}
            ></IonInput>
          </IonItem>
          <IonItem>
            <IonInput
              className="ion-text-right"
              label="Allievo"
              name="description"
              value={full_name}
              placeholder="Allievo"
              disabled
            ></IonInput>
          </IonItem>

          <IonItem onClick={() => setShowFromCalendarModal(true)}>
            <IonLabel>Valido dal</IonLabel>
            <div>
              {/* Mostra la data selezionata o un trattino se non selezionata */}
              <FormattedDate
                value={fromDate}
                day="2-digit"
                month="2-digit"
                year="2-digit"
              />
            </div>

            {/* Modale per la selezione della data */}
          </IonItem>
          <IonModal
            isOpen={showFromCalendarModal}
            onDidDismiss={() => setShowFromCalendarModal(false)}
          >
            <IonDatetime
              className="EditUserDatePicker"
              presentation="date"
              value={fromDate.toISOString()}
              onIonChange={
                // handleDateChange
                (e) => {
                  setFromDate(new Date(e.detail.value));
                  setShowFromCalendarModal(false);
                }
              }
            />
            <IonButton onClick={() => setShowFromCalendarModal(false)}>
              Chiudi
            </IonButton>
          </IonModal>
          <IonItem onClick={() => setShowToCalendarModal(true)}>
            <IonLabel>Valido al</IonLabel>
            <div>
              {/* Mostra la data selezionata o un trattino se non selezionata */}
              <FormattedDate
                value={toDate}
                day="2-digit"
                month="2-digit"
                year="2-digit"
              />
            </div>

            {/* Modale per la selezione della data */}
          </IonItem>
          <IonModal
            isOpen={showToCalendarModal}
            onDidDismiss={() => setShowToCalendarModal(false)}
          >
            <IonDatetime
              className="EditUserDatePicker"
              presentation="date"
              value={toDate.toISOString()}
              onIonChange={
                // handleDateChange
                (e) => {
                  setToDate(new Date(e.detail.value));
                  setShowToCalendarModal(false);
                }
              }
            />
            <IonButton onClick={() => setShowFromCalendarModal(false)}>
              Chiudi
            </IonButton>
          </IonModal>
          <IonItem>
            <IonInput
              type="number"
              min={1}
              required
              className="ion-text-right"
              label="Lezioni minime"
              name="description"
              value={minimumLessons}
              placeholder="Lezioni minime"
              onIonInput={(e) => setMinimumLessons(e.detail.value)}
            ></IonInput>
          </IonItem>
        </IonList>
        <IonText>
          <h4>Lezioni</h4>
        </IonText>
        <IonList>
          {lessonLogs
            .sort((a, b) => a.from_date._seconds - b.from_date._seconds)
            .map((lessonLog) => (
              <IonItem key={lessonLog.id}>
                <IonIcon
                  icon={trashOutline}
                  slot="end"
                  onClick={() => {
                    // remove lesson log and add it to orphan lessons
                    setLessonLogs(
                      lessonLogs.filter((l) => l.id !== lessonLog.id),
                    );
                    setOrphanLessons([...orphanLessons, lessonLog]);
                  }}
                ></IonIcon>
                <IonLabel style={{ display: "flex" }}>
                  {lessonLog.description}
                  <span
                    style={{
                      marginLeft: "auto",
                      fontWeight: "normal",
                    }}
                  >
                    <FormattedDate
                      value={lessonLog.from_date._seconds * 1000}
                      day="2-digit"
                      month="2-digit"
                      year="2-digit"
                    />
                  </span>
                </IonLabel>
              </IonItem>
            ))}
        </IonList>
        {orphanLessons.length > 0 && (
          <IonText>
            <h4>Lezioni senza gruppo</h4>
          </IonText>
        )}
        <IonList>
          {orphanLessons
            .sort((a, b) => a.from_date._seconds - b.from_date._seconds)
            .map((lessonLog) => (
              <IonItem key={lessonLog.id}>
                <IonIcon
                  icon={addCircleOutline}
                  slot="end"
                  onClick={() => {
                    // remove from orphan lessons and add it to lesson logs
                    setOrphanLessons(
                      orphanLessons.filter((l) => l.id !== lessonLog.id),
                    );
                    setLessonLogs([...lessonLogs, lessonLog]);
                  }}
                ></IonIcon>
                <IonLabel style={{ display: "flex" }}>
                  {lessonLog.description}
                  <span
                    style={{
                      marginLeft: "auto",
                      fontWeight: "normal",
                    }}
                  >
                    <FormattedDate
                      value={lessonLog.from_date._seconds * 1000}
                      day="2-digit"
                      month="2-digit"
                      year="2-digit"
                    />
                  </span>
                </IonLabel>
              </IonItem>
            ))}
        </IonList>
        <IonAlert
          isOpen={openDeleteAlert}
          header="Vuoi cancellare il gruppo di lezioni?"
          trigger="present-alert"
          buttons={[
            {
              text: "Annulla",
              role: "cancel",
              handler: async () => { },
            },
            {
              text: "Conferma",
              role: "confirm",
              handler: async () => {
                await deleteGroupLog();
              },
            },
          ]}
          onDidDismiss={({ detail }) =>
            console.log(`Dismissed with role: ${detail.role}`)
          }
        ></IonAlert>
      </IonContent>
      <IonFooter className="ion-no-border ion-padding">
        <IonButton
          color="danger"
          expand="block"
          size="large"
          onClick={() => setOpenDeleteAlert(true)}
        >
          Cancella
        </IonButton>
      </IonFooter>
    </IonModal>
  );
}

SubscriptionEditModal.propTypes = {
  hideSelectedAthletes: PropTypes.bool,
  isOpen: PropTypes.bool,
  athletes: PropTypes.array,
  selectedAthletes: PropTypes.array,
  onDismiss: PropTypes.func,
  onConfirm: PropTypes.func,
  subscription: PropTypes.shape({
    id: PropTypes.string,
    from_date: PropTypes.shape({ _seconds: PropTypes.number }),
    to_date: PropTypes.shape({ _seconds: PropTypes.number }),
    participants: PropTypes.array,
    fieldName: PropTypes.string,
    clubName: PropTypes.string,
    label: PropTypes.string,
    description: PropTypes.string,
    paid: PropTypes.bool,
    payment_date: PropTypes.shape({ seconds: PropTypes.number }),
    payment_channel: PropTypes.string,
  }),
};

export default SubscriptionEditModal;
