import React, { useContext, useEffect, useState } from "react";
import {
  IonContent,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  useIonViewWillEnter,
} from "@ionic/react";
import useAPI from "../../hooks/basic/useAPI";
import useMatchSnapshot from "../../hooks/match/useMatchSnapshot";
import MatchCard from "../../components/MatchCard/MatchCard";
import FirstService from "./sub/FirstService";
import InGame from "./sub/InGame";

const today = new Date();
const tomorrow = new Date(today);
tomorrow.setDate(tomorrow.getDate() + 2);

const MatchPage = ({ match }) => {
  const matchId = match.params.matchId;
  const { callAPI } = useAPI();
  const [matchData, setMatch] = useState(null);
  const { data, loading, error } = useMatchSnapshot(`/matches/${matchId}`);

  const loadMatch = async () => {
    try {
      const response = await callAPI({
        url: `/match`,
        params: {
          id: matchId,
        },
      });
      setMatch(response);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    loadMatch();
  }, []);

  if (!data) {
    return null;
  }

  const { currentStatus } = data;

  const selectServicePlayer = async (athleteId) => {
    try {
      await callAPI({
        url: `/match-action`,
        method: "POST",
        data: {
          matchId: data.id,
          action: "select_first_player",
          data: {
            athleteId,
          },
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  const sendAction = async (action, actionData) => {
    try {
      await callAPI({
        url: `/match-action`,
        method: "POST",
        data: {
          matchId: data.id,
          action,
          data: actionData,
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <IonPage>
      <IonContent id="match-page">
        <MatchCard match={data} />
        Squadra A: {data.team_a.map((player) => player.name).join(", ")}
        <br />
        Squadra B: {data.team_b.map((player) => player.name).join(", ")}
        <h1>Stato attuale {currentStatus.phase}</h1>
        {currentStatus.phase === "initialized" && (
          // select player who will serve first
          <div>
            Seleziona il giocatore che farà il primo servizio
            <ul>
              {data.team_a.map((player) => (
                <li key={player.athleteId}>
                  {player.name}
                  <button
                    onClick={() => {
                      selectServicePlayer(player.athleteId);
                    }}
                  >
                    Seleziona
                  </button>
                </li>
              ))}
              {data.team_b.map((player) => (
                <li key={player.athleteId}>
                  {player.name}
                  <button
                    onClick={() => {
                      selectServicePlayer(player.athleteId);
                    }}
                  >
                    Seleziona
                  </button>
                </li>
              ))}
            </ul>
          </div>
        )}
        {["first_service", "second_service"].indexOf(currentStatus.phase) >= 0 && (
          <FirstService isSecondService={currentStatus.phase === "second_service"} onAction={sendAction} currentStatus={currentStatus} />
        )}
        {currentStatus.phase === "in_game" && (
          <InGame match={data} onAction={sendAction} currentStatus={currentStatus} />
        )}
        {/* {JSON.stringify(data)} */}
      </IonContent>
    </IonPage>
  );
};

export default MatchPage;
